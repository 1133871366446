<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <b-row>
                            <b-col
                            md="6"
                            class="d-flex justify-content-start"
                            
                            >
                            <h4 class="text-dark font-weight-bold">เพิ่มคิวบริการ</h4>
                            
                            </b-col>
                            <b-col
                            md="6"
                            class="d-flex justify-content-end"
                            >
                                <b-button  
                                type="submit"
                                variant="primary"
                                    >
                                    <feather-icon icon="PlusIcon" />
                                    เพิ่มคิว
                                </b-button>
                            </b-col>
                        </b-row>
            </div>
        </b-card>
    </div>
</template>
<script>
import {
    BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,VBToggle,BFormDatepicker
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
///import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'


import { ref,onUnmounted } from '@vue/composition-api'

import { formatDateTh,formatDateToMonthShort,getCommaDecimal } from '@core/utils/filter'

import vSelect from 'vue-select'
import router from '@/router'
//import receiptStoreModule from '../receiptStoreModule'
//import useReceipt from './useReceipt'
//import html2pdf from 'html2pdf.js'
import VueHtml2pdf from 'vue-html2pdf'
import reportStoreModule from '../reportStoreModule'
export default {
    components:{
        BCard, BRow, BCol, BCollapse, BSidebar, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BImg,BFormSelect,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,VBModal,BFormGroup,BFormCheckbox,
    BInputGroup,BInputGroupPrepend,BInputGroupAppend,VBToggle,BFormDatepicker,
    VueGoodTable

    },
    directives:{
        'b-toggle': VBToggle,
        'b-modal': VBModal,
        Ripple

    },
    data(){
        return {
            formatDateTh,
            formatDateToMonthShort,
            getCommaDecimal
        }
    },
    setup(props, { emit }){
    }
    
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>